<template>
  <div>
    <b-card
      no-body
    >
      <b-card-body>
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('ENTRIES') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              :to="{ name: 'media-configuration'}"
            >
              <span> {{ $t('ADD_MEDIA') }} </span>
              <feather-icon icon="PlusCircleIcon" />
            </b-button>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('SEARCH')"
              />
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- media -->
    <b-row class="blog-list-wrapper">
      <b-col
        v-for="media in paginatedMedias"
        :key="media.id"
        md="6"
      >
        <b-card
          tag="article"
          no-body
        >
          <b-link :to="{ name: 'media-configuration-id', params: { id: media.id } }">
            <b-embed
              type="iframe"
              :src="media.url"
              allowfullscreen
              class="rounded mb-50"
            />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{ name: 'media-configuration-id', params: { id: media.id } }"
                class="blog-title-truncate text-body-heading"
              >
                {{ media.name }}
              </b-link>
            </b-card-title>
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              />
            </b-media>
            <b-card-text class="blog-content-truncate">
              <p v-html="media.description" />
            </b-card-text>
            <div>
              <small class="text-muted">
                <b-badge
                  pill
                  :variant="mapLanguageToVariant(media.language)"
                  class="text-capitalize small"
                >
                  {{ resolveLanguage(media.language) }}
                </b-badge>
              </small>
            </div>
            <hr>
            <div class="d-flex align-items-center">
              <feather-icon
                :id="media.id"
                icon="EditIcon"
                size="16"
                class="ml-1"
                @click="$router.push({ name: 'media-configuration-id', params: { id: media.id }})"
              />
              <feather-icon
                :id="media.id"
                icon="Trash2Icon"
                size="16"
                class="ml-1"
                @click="confirmAndDeleteMedia(media)"
              />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="totalMedias"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  <!--/ media -->
  </div>
</template>

<script>
import {
  BRow, BCol, BCard,
  BCardText, BEmbed,
  BCardTitle, BMedia,
  BMediaAside, BBadge,
  BCardBody, BLink,
  BPagination, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'
import {
  mapLanguageToVariant,
} from '@/constants/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BBadge,
    BLink,
    BEmbed,
    BPagination,
    BFormInput,
    BButton,
    vSelect,
  },
  data() {
    return {
      search_query: '',
      currentPage: 1,
      searchQuery: '',
      sortBy: 'id',
      isSortDesc: true,
      perPage: constants.PER_PAGE,
      perPageOptions: constants.PER_PAGE_OPTIONS,
    }
  },
  computed: {
    ...mapState('media', ['allMedias']),
    totalMedias() {
      return this.filteredMedias.length
    },
    dataMeta() {
      const localItemsCount = this.paginatedMedias.length
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalMedias,
      }
    },
    filteredMedias() {
      let data = this.allMedias || []
      data = data.filter(media => !media.url.endsWith('.png') && !media.url.endsWith('.jpeg') && !media.url.endsWith('.jpg'))
      // Filter by search query
      if (this.searchQuery) {
        const queryLowered = this.searchQuery.toLowerCase()
        data = data.filter(media => (media.name && media.name.toLowerCase().includes(queryLowered))
          || (media.language && media.language.toLowerCase().includes(queryLowered)))
      }
      // Sort the data
      data = data.sort((a, b) => {
        const aValue = a[this.sortBy]
        const bValue = b[this.sortBy]

        // If sorting by ID, ensure numeric sorting
        if (this.sortBy === 'ID') {
          return this.isSortDesc ? bValue - aValue : aValue - bValue
        }
        // Otherwise, sort alphabetically
        const aStr = aValue ? aValue.toString().toLowerCase() : ''
        const bStr = bValue ? bValue.toString().toLowerCase() : ''

        if (this.isSortDesc) {
          return aStr < bStr ? 1 : -1
        }
        return aStr > bStr ? 1 : -1
      })
      return data
    },
    paginatedMedias() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.filteredMedias.slice(start, end)
    },
  },
  created() {
    this.fetchAllMedias()
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
      mapLanguageToVariant,
    }
  },
  methods: {
    ...mapActions('media', ['fetchAllMedias', 'deleteMedia']),
    confirmAndDeleteMedia(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.name}  ${this.$i18n.t('Media')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.handleDeleteMedia(data)
          }
        })
    },
    handleDeleteMedia(data) {
      if (data) {
        this.deleteMedia(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.MEDIA_DELETED'))
            this.fetchAllMedias()
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
    resolveLanguage(data) {
      const language = constants.LANGUAGES.find(i => i.id === data)
      if (language) {
        return language.name
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
